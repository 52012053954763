<template>
  <app-page
    title="Enroll employee"
    :previous="[
      { label: 'Employees', to: { name: 'employees' } },
      {
        label: employee ? employee.full_name : 'Employee',
        to: { name: 'employee', params: { employeeId: $route.params.employeeId } },
      },
    ]"
  >
    <template v-if="!hasLaunched" v-slot:after-header>
      <sme-alert level="warning" class="mt-3">
        <strong>Warning: </strong> You cannot enroll employees until you have completed the necessary setup tasks.
      </sme-alert>
    </template>

    <template v-if="!loading && employee && employee.employee_id" v-slot:header>
      <b-button
        variant="outline-primary"
        :to="{ name: 'employee-deactivate', params: { employeeId: employee.employee_id } }"
      >
        <font-awesome-icon :icon="['fad', 'user-minus']" class="mr-2" />Disable
      </b-button>
    </template>

    <template v-slot:infobox>
      <sme-info-box title="What is employee enrollment?" id="about-enrollment" save-state>
        <p>Enrollment is what happens when an employee requests to use the app.</p>
        <p>
          Unless it is automated as part of your
          <router-link :to="{ name: 'sme-integrations' }">integration</router-link>, you will need to confirm access and
          ensure that employee details are properly synchronised with your payroll system.
        </p>
        <p>
          <strong>Getting this process right is important, but really quite simple:</strong>
        </p>
        <ol>
          <li>Provide us the employee's details including banking from your payroll system below.</li>
          <li>
            When you submit, we will then create a Wagestream account for that employee and give you the
            {{ getBankingInfo.accountNumber.label }} and {{ getBankingInfo.sortCode.label }}.
          </li>
          <li>You can then update your payroll system to pay into the Wagestream account instead.</li>
        </ol>
        <p>
          Banking information for all enrolled employees is available on the
          <router-link :to="{ name: 'sme-pay-day' }">Payday</router-link> page should you wish to confirm payroll
          details or update them in bulk.
        </p>
      </sme-info-box>
      <sme-info-box title="What is an employee code?" id="employee-code" save-state>
        <p>
          All employees must have a unique <strong>employee code</strong>. We will generate these for you by default
          (e.g. WS-00001) however we recommend that you change them to correspond to the same identifiers used in e.g.
          your payroll software.
        </p>
        <p>
          You need to ensure that your choice of employee code allows us to attribute worked
          <router-link :to="{ name: 'shifts' }">shifts</router-link> to employees. That is, the same code should appear
          in both.
        </p>
        <p>
          This is all handled automatically by
          <router-link :to="{ name: 'sme-integrations' }">integration</router-link> so is primarily a concern for
          companies that perform manual shift uploads.
        </p>
      </sme-info-box>
    </template>

    <app-loading :loading="loading" />
    <template v-if="!loading">
      <app-input v-model="employee.work_email" type="text" name="full name" label="Email" readonly></app-input>

      <ValidationObserver v-slot="v">
        <b-form @submit.prevent="v.handleSubmit(onSubmit)">
          <app-input
            v-model="employee.employee_code"
            type="text"
            name="employee code"
            rules="required"
            label="Employee Code"
          ></app-input>

          <app-input
            v-model="employee_name"
            type="text"
            name="full name"
            :rules="{ required: true, regex: /^[a-zA-ZÀ-ÿ0-9 .-/&]+$/ }"
            placeholder="Enter full name"
            description="The employee's full name. Only letters, numbers, full stop, hyphen, forward slash or ampersand are permitted"
            label="Full Name"
          ></app-input>

          <app-input
            v-model="setup.salary_or_hourly"
            type="radio"
            name="salary or hourly"
            rules="required"
            label="How are they paid?"
            :options="salaryOrHourlyOptions"
            description="This changes how we calculate earned wages"
            buttons
            button-variant="outline-primary"
          ></app-input>
          <template v-if="setup.salary_or_hourly == EMPLOYEE_PAY_TYPE.HOURLY && canSetDefaultWagerate">
            <app-input
              v-model="setup.default_wagerate"
              type="number"
              name="Rate"
              step="0.01"
              :rules="WAGERATE_VALIDATION_RULES"
              :label="`Hourly Rate (in ${default_currency})`"
            ></app-input>
          </template>

          <template v-if="setup.salary_or_hourly == EMPLOYEE_PAY_TYPE.SALARY">
            <app-input
              v-model="setup.salary"
              type="number"
              name="Salary"
              step="0.01"
              :rules="SALARY_VALIDATION_RULES"
              :label="`Gross salary (in ${default_currency})`"
            ></app-input>
            <app-input
              v-model="setup.salary_period"
              type="radio"
              name="Salary Period"
              rules="required"
              label="per"
              :options="salaryPeriodOptions"
              description="Balance will update automatically."
            ></app-input>
            <template v-if="canSetDefaultWagerate">
              <app-input
                v-model="setup.default_wagerate"
                type="number"
                name="Rate"
                step="0.01"
                :rules="Object.assign({}, WAGERATE_VALIDATION_RULES, { required: false })"
                :label="`Hourly Rate (in ${default_currency})`"
              ></app-input>
            </template>
            <app-input
              v-model="setup.started_on"
              type="date"
              name="Start Date"
              rules="required"
              label="Start Date"
            ></app-input>
            <app-input
              v-model="setup.salary_accrual_schedule"
              type="select"
              name="Accrual Schedule"
              rules="required"
              undefined-label="Select an Accrual Schedule"
              :options="salaryAccrualSchedules"
              label="Accrual Schedule"
            >
            </app-input>
          </template>

          <app-input
            v-model="setup.sort_code"
            type="text"
            :name="getBankingInfo.sortCode.label"
            :placeholder="getBankingInfo.sortCode.placeholder"
            :rules="{ required: true, regex: getBankingInfo.sortCode.regex }"
            :label="getBankingInfo.sortCode.label"
            :description="getBankingInfo.sortCode.description"
            :readonly="!canUpdateBanking"
          ></app-input>

          <app-input
            v-model="setup.account_number"
            type="text"
            :name="getBankingInfo.accountNumber.label"
            :placeholder="getBankingInfo.accountNumber.placeholder"
            :rules="{ required: true, regex: getBankingInfo.accountNumber.regex }"
            :label="getBankingInfo.accountNumber.label"
            :description="getBankingInfo.accountNumber.description"
            :readonly="!canUpdateBanking"
          ></app-input>

          <app-input
            v-if="canSetAccountType"
            v-model="setup.account_type"
            type="select"
            :name="getBankingInfo.accountType.label"
            :placeholder="getBankingInfo.accountType.placeholder"
            :label="getBankingInfo.accountType.label"
            :description="getBankingInfo.accountType.description"
            :options="getBankingInfo.accountType.options"
          ></app-input>

          <app-input
            v-model="paySchedule"
            type="select"
            name="Pay Schedule"
            :options="paySchedules"
            rules="required"
            label="Pay Schedule"
            description="Choose the pay schedule for the employee"
            key="pay_schedule_id"
          ></app-input>

          <page-sub-footer>
            <template #before>
              <sme-alert v-if="error" level="danger">{{ error }}</sme-alert>
            </template>

            <b-button
              type="submit"
              variant="primary"
              :disabled="v.invalid || saving || !hasLaunched || isInPayrollBlackout"
            >
              <b-spinner v-if="saving" class="mr-2" small />
              {{ saving ? 'Submitting...' : 'Submit' }}
            </b-button>
          </page-sub-footer>
          <!-- TODO we may actually want to use the CAN_ENROLL_EMPLOYEES feature flag here -->
          <span v-if="!hasLaunched" class="d-block mt-2 text-danger"
            >You must finish onboarding before you can enroll employees.</span
          >
          <span v-else-if="isInPayrollBlackout" class="d-block mt-2 text-danger"
            >Employee is in a payroll blackout period. Please try again after payday.</span
          >
        </b-form>
      </ValidationObserver>
    </template>
  </app-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import AppInput from '@/components/AppInput.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppPage from '@/components/AppPage.vue';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import SmeInfoBox from '@/components/atoms/SmeInfoBox.vue';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';
import {
  EMPLOYEE_PAY_TYPE,
  SALARY_VALIDATION_RULES,
  WAGERATE_VALIDATION_RULES,
  updateEmployeeAndBanking,
} from '@/utils/Employee';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { employeeIsOnPayscheduleInBlackout } from '@/utils/Payschedule';

const defaultSetup = {
  worked_on: undefined,
  employee_id: null,
  hours: undefined,
  rate: undefined,

  salary_accrual_schedule: undefined,

  sort_code: undefined,
  account_number: undefined,
  account_type: undefined,

  // SME defaults
  salary_or_hourly: EMPLOYEE_PAY_TYPE.HOURLY,
  salary: undefined,
  salary_period: 'year',
  default_wagerate: undefined,
  started_on: undefined,
};

export default {
  name: 'EnrollEmployee',
  components: { ValidationObserver, AppInput, AppLoading, AppPage, PageSubFooter, SmeAlert, SmeInfoBox },
  data() {
    return {
      EMPLOYEE_PAY_TYPE,
      SALARY_VALIDATION_RULES,
      WAGERATE_VALIDATION_RULES,
      loading: true,
      error: undefined,
      saving: false,
      setup: { ...defaultSetup },
      employee: undefined,
      state: State.state,
      integrationsState: IntegrationsState,
      hasLaunched: false,
      isInPayrollBlackout: false,

      newSortCode: undefined,
      newAccountNumber: undefined,
      employee_name: undefined,
      paySchedule: null,

      salaryOrHourlyOptions: [
        { value: EMPLOYEE_PAY_TYPE.SALARY, text: 'Salary' },
        { value: EMPLOYEE_PAY_TYPE.HOURLY, text: 'Shift' },
      ],
      salaryPeriodOptions: [
        { value: 'year', text: 'Year' },
        { value: 'month', text: 'Month' },
      ],
      salaryAccrualSchedules: [
        { value: 'WEEKDAY0917', text: 'Weekdays, 9am—5pm GMT' },
        {
          value: 'EVERYDAY0917',
          text: 'Every day (including weekends!), 9am—5pm GMT',
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      this.error = undefined;
      this.saving = true;
      this.updateEmployeeSalary();
    },
    async updateEmployeeSalary() {
      if (this.employee_name) {
        this.employee.full_name = this.employee_name;
      }
      const newEmployee = { ...this.employee };

      if (this.setup.salary_or_hourly === EMPLOYEE_PAY_TYPE.HOURLY) {
        newEmployee.default_wagerate = this.setup.default_wagerate * 100; // Convert to pence
        newEmployee.salary_properties = {
          salary_or_hourly: EMPLOYEE_PAY_TYPE.HOURLY,
          default_wagerate: newEmployee.default_wagerate,
        };
      } else if (this.setup.salary_or_hourly === EMPLOYEE_PAY_TYPE.SALARY) {
        newEmployee.salary_properties = {
          salary_or_hourly: EMPLOYEE_PAY_TYPE.SALARY,
          salary: Math.round(this.setup.salary),
          salary_period: this.setup.salary_period.toUpperCase(),
          salary_accrual_schedule: this.setup.salary_accrual_schedule,
        };
        // Check if newEmployee.default_wagerate is not null
        if (this.setup.default_wagerate !== null) {
          const defaultWageRate = this.setup.default_wagerate * 100; // Convert to pence
          newEmployee.default_wagerate = defaultWageRate;
          newEmployee.salary_properties.default_wagerate = defaultWageRate;
        }
      }

      if (this.paySchedule) {
        newEmployee.pay_schedule_id = this.paySchedule;
      }

      this.employee = {
        ...this.employee,
        ...newEmployee,
        properties: {
          beneficiary_account_number: this.setup.account_number,
          beneficiary_sort_code: this.setup.sort_code,
        },
        started_on: this.setup.started_on,
      };

      // Only companies with the canSetAccountType feature flag can set the account type
      if (this.setup.account_type) {
        this.employee.properties.beneficiary_type = this.setup.account_type;
      }

      try {
        await updateEmployeeAndBanking(this.employee);
      } catch (error) {
        this.error = error.message;
        this.loading = false;
        this.saving = false;
        return;
      }

      await this.updateEmployeeState();
    },
    async updateEmployeeState() {
      const newState = {
        state: 'ENROLLING',
        banking_info: {
          sort_code: this.setup.sort_code,
          account_number: this.setup.account_number,
          // Account type is optional, should only be set if the feature flag is enabled
          account_type: this.setup.account_type,
        },
      };

      try {
        await ApiClient.updateEmployeeState(this.employee.employee_id, newState);
        await ApiClient.getUser();
        this.$router.push({
          name: 'employee-activate',
          params: { employeeId: this.employee.employee_id },
        });
      } catch (error) {
        this.onError(error);
      }
    },
    async loadData() {
      this.loading = true;
      try {
        const employee = await ApiClient.getEmployee(this.$route.params.employeeId);
        this.employee = employee;
        this.employee_name = employee.full_name;

        if (employee.salary_properties) {
          this.setup = {
            ...this.setup,
            salary_or_hourly: employee.salary_properties.salary_or_hourly,
            salary: employee.salary_properties.salary,
            salary_period: (employee.salary_properties.salary_period || this.setup.salary_period).toLowerCase(),
            salary_accrual_schedule: this.state?.company?.properties?.default_salary_accrual_schedule || 'WEEKDAY0917',
          };
        }

        this.setup.started_on = employee.started_on;
        this.default_currency = employee.default_currency ?? 'GBP';

        if (employee.properties?.beneficiary_account_number) {
          // Banking is setup
          this.setup.account_number = employee.properties.beneficiary_account_number;
          this.setup.sort_code = employee.properties.beneficiary_sort_code;
          this.setup.account_type = employee.properties.beneficiary_type;
        } else if (employee.integration_properties?.personal_account_number) {
          // Already synced from an integration
          this.setup.account_number = employee.integration_properties.personal_account_number;
          this.setup.sort_code = employee.integration_properties.personal_sort_code;
        } else if (employee.properties?.sme_properties?.personal_account_number) {
          // Already stored as part of pay with Wagestream
          this.setup.account_number = employee.properties.sme_properties.personal_account_number;
          this.setup.sort_code = employee.properties.sme_properties.personal_sort_code;
        }

        const paySchedulesResponse = await ApiClient.getCompanyPaySchedules(this.state.company.company_id);
        this.paySchedules = paySchedulesResponse.data.map(x => {
          return { text: x.name, value: x.pay_schedule_id };
        });
        this.paySchedule = this.paySchedules[0].value;
        this.loading = false;
      } catch (error) {
        this.onError(error);
      }
    },
    onError(error) {
      this.error = error?.message || error;
      this.loading = false;
      this.saving = false;
    },
  },
  async mounted() {
    this.hasLaunched = this.state?.company?.properties?.is_soft_launch !== true;
    await this.loadData();
    this.isInPayrollBlackout = await employeeIsOnPayscheduleInBlackout(this.employee);
  },
  computed: {
    getBankingInfo: () => getBankingInfo(),
  },
  setup() {
    const { canSetAccountType, canSetDefaultWagerate, canUpdateBanking } = useFeatureFlags();

    return {
      canSetAccountType,
      canSetDefaultWagerate,
      canUpdateBanking,
    };
  },
};
</script>
