import { computed, h, ref } from 'vue';
import State from '@/state/State';
import { getPartnershipData } from '@/utils/partnership';

const { featureFlags: partnershipFeatureFlags } = getPartnershipData();

const isFlagEnabled = flag =>
  computed(
    () =>
      State.state.company?.properties?.b2b_features?.variations_whitelist?.includes(flag) ||
      State.state.company?.properties?.portal?.[flag] ||
      partnershipFeatureFlags?.[flag] ||
      false,
  );

const isSme = computed(() => State.state.company?.properties?.Author === 'company_registration');

const flags = {
  canAddEmployees: isFlagEnabled('CAN_ADD_EMPLOYEES'),
  canAddSingleEmployee: isFlagEnabled('CAN_ADD_SINGLE_EMPLOYEE'),
  canAddShifts: isFlagEnabled('CAN_ADD_SHIFTS'),
  canAddSingleShift: isFlagEnabled('CAN_ADD_SINGLE_SHIFT'),
  canViewAbsences: isFlagEnabled('CAN_VIEW_ABSENCES'),
  canAddAbsences: isFlagEnabled('CAN_ADD_ABSENCES'),
  canEnrollEmployees: computed(
    () =>
      isFlagEnabled('CAN_ENROLL_EMPLOYEES').value &&
      // todo check this logic, should soft launch be able to enroll employees?
      (State.state?.company?.properties?.is_soft_launch || State.state?.company?.partner_state === 'LIVE'),
  ),
  canDisableEmployees: isFlagEnabled('CAN_DISABLE_EMPLOYEES'),
  canUpdateBanking: isFlagEnabled('CAN_UPDATE_BANKING'),
  canIntegrate: isFlagEnabled('CAN_INTEGRATE'),
  canUpdatePayschedule: isFlagEnabled('CAN_UPDATE_PAYSCHEDULE'),
  canLaunchSelf: isFlagEnabled('CAN_LAUNCH_SELF'),
  canSalarySignOff: isFlagEnabled('CAN_SALARY_SIGN_OFF'),
  canSignTerms: isFlagEnabled('CAN_SIGN_TERMS'),
  isSubscription: isFlagEnabled('IS_SUBSCRIPTION'),
  isKYC: isFlagEnabled('IS_KYC'),
  isSelfFunded: isFlagEnabled('IS_SELF_FUNDED'),
  isDisablePayscheduleEditing: computed(
    () => isFlagEnabled('disable_payschedule_editing').value || !isFlagEnabled('CAN_UPDATE_PAYSCHEDULE').value,
  ),
  isDisableIntegratedEmployeeFieldsStrict: isFlagEnabled('disable_integrated_employee_fields_strict'),
  isDisableLandingPageLinks: isFlagEnabled('disable_landing_page_links'),
  isDisablePartnerLogos: isFlagEnabled('disable_partner_logos'),
  isDisablePayWithWagestream: isFlagEnabled('disable_pay_with_wagestream'),
  isDisableRegistration: isFlagEnabled('disable_registration'),
  isDisableOffCyclePayments: isFlagEnabled('disable_off_cycle_payments'),
  isEnableEmployeePauseScheduling: isFlagEnabled('enable_employee_pause_scheduling'),
  isEnableEmployeeForm: isFlagEnabled('enable_employee_form'),
  isEnableExpandedEmployeeStreamingFormFields: isFlagEnabled('enable_expanded_employee_streaming_form_fields'),
  isEnableHideDisabled: isFlagEnabled('hide_disabled'),
  isEnableHideNonEnrolled: isFlagEnabled('hide_non_enrolled'),
  isEnableFlexibleWork: computed(() => State.state.company?.properties?.flexible_work?.enabled || false),
  isEnableFlexibleWorkAudience: ref(false),
  isEnableFlexibleWorkBoost: ref(false),
  isEnableFlexibleWorkManagerShiftSignOff: computed(
    () => State.state.company?.properties?.flexible_work?.shift_manager_can_sign_off_shift || false,
  ),
  isEnabledFlexibleWorkFixedShiftRates: computed(
    () => State.state.company?.properties?.flexible_work?.allow_fixed_shift_rates || false,
  ),
  isEnableTalentPool: isFlagEnabled('talent_pool_portal_enabled'),
  isEnableStreamedWagesPage: isFlagEnabled('show_streamed_wages_page'),
  isEnableLumpSumPayPage: isFlagEnabled('enable_lump_sum_pay'),
  isEnableEmployeePaymentsPage: isFlagEnabled('show_employee_payments_report'),
  isFundingPositionPageDisabled: isFlagEnabled('CANNOT_VIEW_FUNDING_POSITION'),
  isEnableShowBankingFile: isFlagEnabled('show_banking_file'),
  isEnableWhatsApp: computed(() => State.state.company?.properties?.b2b_features?.whatsapp_comms || isSme.value),
  isEnableAdoptionGamification: computed(
    () => State.state.company?.properties.b2b_features?.adoption_gamification || isSme.value,
  ),
  isUseUserCountInsteadOfEmployeesCount: computed(
    () => State.state.company?.properties?.dashboards_count_users || false,
  ),
  // a singular pay_day ensures only weekly pay companies can see their history
  isEnableBankPayHistory: computed(
    () =>
      (State.state.company?.properties?.weekly_pay_settings?.enabled &&
        State.state.company?.properties?.weekly_pay_settings?.pay_day) ||
      false,
  ),
  isSme: isSme,
  canViewIntegrationHistory: isFlagEnabled('CAN_VIEW_INTEGRATIONS_HISTORY'),
  shouldSignDbTermsAddendum: computed(
    () => State?.state?.company?.properties?.should_accept_salary_service_addendum_db || false,
  ),
  allowEditingAddress: isFlagEnabled('CAN_EDIT_EMPLOYEE_ADDRESS'),
  canSetAccountType: isFlagEnabled('CAN_SET_ACCOUNT_TYPE'),
  canSetDefaultWagerate: isFlagEnabled('CAN_SET_DEFAULT_WAGERATE'),
  displayNetPayShifts: isFlagEnabled('DISPLAY_NET_PAY_SHIFTS'),
  showLeaversAsDisabled: isFlagEnabled('SHOW_LEAVERS_AS_DISABLED'),
  hideShiftsHoursAndRate: isFlagEnabled('hide_shifts_hours_and_rate'),
  isEnableRaiseJobs: isFlagEnabled('raise_jobs_enabled'),
  isEnableSdl:
    isFlagEnabled('sdl_enabled') ||
    computed(() => State?.state?.company?.properties?.app_features?.sdl_enabled || false),
  isEnableRaisePortalAdvertisment: isFlagEnabled('raise_portal_advertisment_enabled'),
  hideStreamAccountRelatedItems: isFlagEnabled('hide_stream_account_related_items'),
};

const FeatureFlagWrapper = (defaultComponent, enabledComponent, flag) => ({
  render() {
    return h(flag in flags && flags[flag].value ? enabledComponent : defaultComponent);
  },
});

export default () => ({
  ...flags,
  FeatureFlagWrapper,
});
